window.onload = function () {
  const openModalButtons = [
    'openModalButtonSubscribeTop',
    'openModalButtonSubscribeBot',
    'openModalButtonAbout',
    'openModalButtonHero',
    'openModalButtonServices',
    'openModalButtonMarketing',
    'openModalButtonImprove',
  ];

  const modal = document.getElementById('modal');

  openModalButtons.forEach(buttonId => {
    const button = document.getElementById(buttonId);

    if (button) {
      button.onclick = function () {
        modal.style.display = 'block';
        document.body.style.overflow = 'hidden';
      };
    }
  });

  modal.onclick = function (event) {
    if (event.target.id === 'modal' || event.target.id === 'btnCloseModal') {
      modal.style.display = 'none';
      document.body.style.overflow = 'auto';
    }
  };
};
